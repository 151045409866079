import React, { useRef } from 'react';
import Navbar from '../components/nav_bar';
import HeroSection from '../components/landing/hero_section';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../theme';
import FeaturesSection from '../components/landing/features_section';
import Footer from '../components/footer';
import DemoSection from '../components/landing/demo';

const HomePage = () => {
    const featuresRef = useRef(null);

    const handleLearnMoreClick = () => {
        featuresRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ minHeight: '100vh', backgroundColor: 'white', position: 'relative' }}>
                <Navbar />
                <HeroSection onLearnMoreClick={handleLearnMoreClick} />
                {/* <DemoSection /> */}
                <div ref={featuresRef}>
                    <FeaturesSection />
                </div>
                <Footer />
            </div>
        </ThemeProvider>
    );
};

export default HomePage;
