import React from 'react';
import { Box, Container, Grid, Typography, IconButton, Link } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import logo from '../assets/logo/logo-white.png';


const Footer = () => {
    return (
        <Box component="footer" sx={{ backgroundColor: 'primary.main', color: 'white', py: 3 }}>
            <Container maxWidth="lg">
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                    <img src={logo} alt="Logo" style={{ height: '40px' }} />
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Company
                        </Typography>
                        <Link href="#" color="inherit" underline="hover">About Us</Link><br />
                        <Link href="#" color="inherit" underline="hover">Blog</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Resources
                        </Typography>
                        <Link href="#" color="inherit" underline="hover">Contact</Link><br />
                        <Link href="#" color="inherit" underline="hover">FAQ</Link><br />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Legal
                        </Typography>
                        <Link href="#" color="inherit" underline="hover">Terms of Service</Link><br />
                        <Link href="#" color="inherit" underline="hover">Privacy Policy</Link><br />
                        <Link href="#" color="inherit" underline="hover">Cookie Policy</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Connect
                        </Typography>
                        <a href="https://twitter.com/VedaAI" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                            <IconButton color="inherit">
                                <TwitterIcon />
                            </IconButton>
                        </a>

                    </Grid>
                </Grid>
                <Typography variant="body2" color="white" align="center" sx={{ pt: 4 }}>
                    © {new Date().getFullYear()} VedaAI. All rights reserved.
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
