import React, { useEffect, useState } from 'react';
import { Typography, Button, Container, Grid, Box, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import Lottie from 'react-lottie';
import anytimeLottie from '../../assets/lottie/personalization.json';
import multiLanguageLottie from '../../assets/lottie/multilingual.json';
import curriculumTailoringLottie from '../../assets/lottie/personalized.json';
import professionalFeedbackLottie from '../../assets/lottie/professional.json';
import accessibilityLottie from '../../assets/lottie/anytime.json';
import wideRangeSubjectsLottie from '../../assets/lottie/anycourse.json';

import personalized_edu from '../../assets/lottie/personalized-edu.json';

const features = [
    {
        title: "Learn by Contributing to Real-World Projects",
        description: "Gain invaluable real-world experience by contributing to open-source projects. Every course section involves hands-on practice, allowing you to apply theoretical knowledge to real-world situations. This approach provides the practical exposure often missing in traditional education, preparing you for success in any professional environment.",
        lottie: anytimeLottie,
        points: [
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Hands-on practice with open-source contributions." },
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Real-world experience to prepare for professional success." }
        ]
    },
    {
        title: "Personalized Learning Paths",
        description: "Embark on a learning journey uniquely tailored to you. Our AI professor adapts to your goals, preferences, and progress, ensuring every step you take is optimized for your success. Experience a personalized education like never before, designed to keep you motivated and on track.",
        lottie: personalized_edu,
        points: [
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Customized curriculum based on your goals and preferences." }
        ]
    },
    {
        title: "Curriculum Tailoring",
        description: "Bring your syllabus to life with AI-driven customization. Simply upload your school or university syllabus, and watch as our AI professor crafts a curriculum that perfectly aligns with your academic requirements and personal learning style. Say goodbye to one-size-fits-all education.",
        lottie: curriculumTailoringLottie,
        points: [
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Upload your syllabus for a tailored learning experience." }
        ]
    },
    {
        title: "Professional Feedback",
        description: "Receive insightful feedback that mirrors the real world. Instead of traditional grades, our AI professor provides detailed reviews on your work, helping you understand your strengths and areas for improvement. By the end of each course, you won't just have theoretical knowledge; you'll have a complete understanding of how to apply it in real-world scenarios. Through hands-on experience with open-source projects, you will gain practical skills that not only enhance your abilities but also boost your credibility in the professional world. Working on these projects makes you visible to potential employers, demonstrating your readiness to contribute effectively in a company setting.",
        lottie: professionalFeedbackLottie,
        points: [
            { icon: <Cancel style={{ color: red[500] }} />, text: "No traditional grades." },
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Real-world experience by working on open-source projects." },
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Open-source contributions in every course." }
        ]
    },
    {
        title: "Accessibility and Flexibility",
        description: "Learn without boundaries. Whether you're at home, on the go, or anywhere in between, access Veda AI Verse anytime, anywhere, on any device. Enjoy a seamless learning experience that fits into your lifestyle, giving you the freedom to study on your terms.",
        lottie: accessibilityLottie,
        points: [
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Learn anytime, anywhere, on any device." },
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Seamless integration with your lifestyle." }
        ]
    },
    {
        title: "Wide Range of Subjects",
        description: "Explore an expansive library of courses spanning STEM, humanities, arts, and more. With Veda AI Verse, you can dive deep into a variety of subjects, from data science to creative writing, all under one platform. Broaden your knowledge and skills across multiple disciplines.",
        lottie: wideRangeSubjectsLottie,
        points: [
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Our platform offers a comprehensive range of courses across all disciplines." },
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Broad knowledge and skills development." }
        ]
    },
    {
        title: "Multi-language Support",
        description: "Break down language barriers with our multi-language support. Learn in the language you're most comfortable with, whether it's English, Mandarin, Spanish, or any other language. Veda AI Verse makes education accessible and inclusive for learners worldwide.",
        lottie: multiLanguageLottie,
        points: [
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Learn in the language of your choice." },
            { icon: <CheckCircle style={{ color: green[500] }} />, text: "Inclusive and accessible education for everyone." }
        ]
    },
];

const FeatureLayoutLeft = ({ feature }) => (
    <Container sx={{ height: { xs: 'auto', md: '100vh' }, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'left' }}>
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Lottie options={{ loop: true, autoplay: true, animationData: feature.lottie }} height={400} width={400} />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h3" component="h2" gutterBottom>
                    {feature.title}
                </Typography>
                <Typography variant="h6" color="textSecondary" paragraph>
                    {feature.description}
                </Typography>
                <List>
                    {feature.points.map((point, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {point.icon}
                            </ListItemIcon>
                            <ListItemText primary={point.text} />
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    </Container>
);

const FeatureLayoutRight = ({ feature }) => (
    <Container sx={{ height: { xs: 'auto', md: '100vh' }, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'left' }}>
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                <Typography variant="h3" component="h2" gutterBottom>
                    {feature.title}
                </Typography>
                <Typography variant="h6" color="textSecondary" paragraph>
                    {feature.description}
                </Typography>
                <List>
                    {feature.points.map((point, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {point.icon}
                            </ListItemIcon>
                            <ListItemText primary={point.text} />
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Lottie
                        options={{ loop: true, autoplay: true, animationData: feature.lottie }}
                        height={{ xs: 300, sm: 500, md: 500 }}
                        width={{ xs: 300, sm: 500, md: 500 }}
                    />
                </Box>
            </Grid>
        </Grid>
    </Container>
);

const FeaturesSection = () => {
    const [init, setInit] = useState(false);

    return (
        <div style={{ position: 'relative', background: '#fff', minHeight: '100vh' }}>
            <div style={{ position: 'relative', zIndex: 1 }}>
                {features.map((feature, index) => (
                    <Box key={index} sx={{ height: { xs: 'auto', md: '100vh' } }}>
                        {index % 2 === 0 ? (
                            <FeatureLayoutRight feature={feature} />
                        ) : (
                            <FeatureLayoutLeft feature={feature} />
                        )}
                        {index < features.length - 1 && (
                            <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.12)', borderWidth: '1px' }} />
                        )}
                    </Box>
                ))}
            </div>
        </div>
    );
};
export default FeaturesSection;