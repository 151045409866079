import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}

    authorizationParams={{
      redirect_uri: process.env.REACT_APP_REDIRECT,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    }}
  >
    <App />
  </Auth0Provider>,
);

reportWebVitals();
