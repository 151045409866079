import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Menu, MenuItem, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/logo/logo.png';
import { LoginButton, LogoutButton } from './auth';
import { useAuth0 } from "@auth0/auth0-react";
import ResponsiveSidebar from './side_bar';

const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { isAuthenticated } = useAuth0();

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" style={{ backgroundColor: 'white', boxShadow: 'none' }}>
            <Toolbar style={{ paddingLeft: '60px' }}>
                <img src={logo} alt="Logo" style={{ height: '40px' }} />
                <Typography variant="h6" component="div" sx={{ paddingLeft: '16px', flexGrow: 1, color: theme.palette.primary.main, transition: 'color 0.3s ease' }}>
                    VedaAI
                </Typography>

                {isAuthenticated ? <LogoutButton /> : <LoginButton />}
                {isMobile ? (
                    <>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose} component={Link} to="/dashboard">Dashboard</MenuItem>
                            <MenuItem onClick={handleClose} component={Link} to="/login">Login</MenuItem>
                        </Menu>
                    </>
                ) : (
                    <>

                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
