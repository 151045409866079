import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Lottie from 'lottie-react';
import HiAnimation from '../assets/lottie/hello.json';
import { Typography, useTheme, useMediaQuery, Button, Box } from '@mui/material';
import LoadingAnimation from '../assets/lottie/loading-professor.json';
import { BASE_WSS } from '../utils/endpoints';
import ConversationBubble from './conversation_bubble';

const SpeechComponent = ({ onPause, onResume }) => {
    const [isListening, setIsListening] = useState(false);
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const wsRef = useRef(null);
    const audioContextRef = useRef(null);
    const audioQueueRef = useRef([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!audioContextRef.current) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        }

        const connectWebSocket = async () => {
            if (!isAuthenticated) {
                console.log("User is not authenticated.");
                return;
            }
            try {
                const token = await getAccessTokenSilently();
                const wsUrl = `${BASE_WSS}getstarted`;

                const newWs = new WebSocket(wsUrl, ['Bearer', token]);
                newWs.binaryType = 'arraybuffer';

                newWs.onopen = () => {
                    console.log('WebSocket connection opened');
                };

                newWs.onmessage = (event) => {
                    setIsListening(true);
                    if (typeof event.data === 'string') {
                        try {
                            const data = JSON.parse(event.data);
                            console.log('Received JSON data:', data);
                            handleCommand(data);
                        } catch (error) {
                            console.error('Failed to parse JSON data:', error);
                        }
                    } else {
                        playAudioChunk(event.data);
                    }
                };

                newWs.onclose = () => {
                    console.log('WebSocket connection closed');
                    setIsListening(false);
                };

                newWs.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    setIsListening(false);
                };

                wsRef.current = newWs;
            } catch (error) {
                console.error('Error getting access token silently:', error);
            }
        };

        connectWebSocket();

        return () => {
            wsRef.current?.close();
            if (audioContextRef.current) {
                audioContextRef.current.close();
                audioContextRef.current = null;
            }
        };
    }, [isAuthenticated, getAccessTokenSilently]);

    const handleCommand = (data) => {
        console.log("handling commands", data.action);
        if (data.action) {
            console.log(data.action);
            switch (data.action) {
                case 'curriculumGenerated':
                    setIsLoading(false);
                    console.log('curriculumGenerated - ', data.curriculum);
                    break;
                case 'loading-true':
                    setIsLoading(true);
                    break;
                case 'loading-false':
                    setIsLoading(false);
                    break;
                default:
                    console.log('Unknown command:', data.action);
            }
        }
    };

    const sendMessage = (message) => {
        if (typeof message === 'string') {
            try {
                const parsedMessage = JSON.parse(message);
                if (parsedMessage.curriculum) {
                    console.log('Sending curriculum data:', message);
                }
            } catch (e) {
                // not a JSON string
            }
        }
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(message);
        } else {
            console.log("WebSocket not ready or closed.");
        }
    };

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                console.log("MediaRecorder started");

                mediaRecorder.ondataavailable = (event) => {
                    sendMessage(event.data);
                };

                mediaRecorder.start(50);

                mediaRecorder.onstop = () => {
                    stream.getTracks().forEach(track => track.stop());
                    console.log("Recording stopped and tracks closed.");
                };
            })
            .catch(error => {
                console.error('Error accessing the media device.', error);
                alert('Could not access microphone. Please check permissions.');
            });
    };

    const handlePause = () => {
        onPause();
        if (audioContextRef.current) {
            audioContextRef.current.suspend().then(() => {
                console.log("Playback suspended");
                sendMessage(JSON.stringify({ command: 'pause' }));
            });
        }
    };

    const handleResume = () => {
        onResume();
        if (audioContextRef.current) {
            audioContextRef.current.resume().then(() => {
                console.log("Playback resumed");
                sendMessage(JSON.stringify({ command: 'play' }));
            });
        }
    };

    const playAudioChunk = (chunk) => {
        const audioCtx = audioContextRef.current;
        console.log("Attempting to decode audio data...");

        audioCtx.decodeAudioData(chunk.slice(0), (buffer) => {
            const source = audioCtx.createBufferSource();
            source.buffer = buffer;
            source.connect(audioCtx.destination);

            let lastEndTime = audioCtx.currentTime;
            if (audioQueueRef.current.length > 0) {
                const lastAudio = audioQueueRef.current[audioQueueRef.current.length - 1];
                lastEndTime = lastAudio.endTime;
            }

            source.start(lastEndTime);
            audioQueueRef.current.push({ source, endTime: lastEndTime + buffer.duration });

            source.onended = () => {
                console.log("Audio playback ended.");
                audioQueueRef.current = audioQueueRef.current.filter(src => src.source !== source);
            };

            console.log("Audio chunk is now playing...");
        }, (e) => {
            console.error("Error with decoding audio data", e);
        });
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log("File uploaded:", file.name);
        }
    };
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', paddingBottom: '10px', height: '100vh' }}>
                <Box
                    sx={{
                        border: '2px dashed #4CAF50',
                        borderRadius: '4px',
                        padding: '20px',
                        textAlign: 'center',
                        width: '80%',
                    }}
                >
                    <Typography variant={isMobile ? 'h6' : 'h5'} style={{ marginBottom: '10px' }}>
                        You can also upload course syllabus or any course content you want to learn
                    </Typography>
                    <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        id="file-upload"
                    />
                    <label htmlFor="file-upload">
                        <Button variant="contained" component="span" style={{ backgroundColor: '#4CAF50', color: 'white' }}>
                            Upload File
                        </Button>
                    </label>
                </Box>
                {!isListening && (
                    <>
                        {isLoading ? (
                            <>
                                <Lottie animationData={LoadingAnimation} style={{ width: 500, height: 500 }} />
                                <Typography variant={isMobile ? 'h6' : 'h5'} style={{ marginRight: '10px', marginLeft: '30px', fontStyle: 'italic' }}>
                                    Please wait, I am working hard to create your curriculum. It will be generated in a moment.
                                </Typography>
                            </>
                        ) : (
                            <Lottie animationData={HiAnimation} style={{ width: 500, height: 500, padding: '10px', marginLeft: '122px' }} />
                        )}
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginBottom: '10px' }}>
                            <button onClick={startRecording} style={{ backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Start Conversation</button>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop: '40px',
                        }}>
                        </div>
                    </>
                )}

                {isListening && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <ConversationBubble />
                    </div>
                )}
            </div>
        </>
    );
};

export default SpeechComponent;