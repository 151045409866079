import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { Sphere, MeshDistortMaterial, OrbitControls } from '@react-three/drei';

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const FullScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

`;

const BubbleContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${float} 2s ease-in-out infinite;
`;


const ConversationBubble = () => (
    <FullScreenContainer>
        <BubbleContainer>
            <Canvas style={{ width: '800px', height: '800px' }}>
                <ambientLight intensity={1} />
                <OrbitControls enableZoom={false} enableRotate={false} />
                <Sphere visible args={[1, 100, 100]} scale={1.5}>
                    <MeshDistortMaterial
                        color="#6096BA"
                        attach="material"
                        distort={0.4}
                        speed={4}
                        flatShading={true}
                    />
                </Sphere>
            </Canvas>
        </BubbleContainer>
    </FullScreenContainer>
);

export default ConversationBubble;
