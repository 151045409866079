import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    useTheme, ThemeProvider, CssBaseline, Typography, Box, Accordion, AccordionSummary, AccordionDetails,
    CircularProgress, Tabs, Tab
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import { BASE_URL } from '../utils/endpoints';

const Topic = memo(({ topic, sectionIndex, topicIndex, onClick, isSelected, isLocked }) => (
    <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={!isLocked ? onClick : null}
        style={{
            padding: '10px',
            cursor: isLocked ? 'not-allowed' : 'pointer',
            borderBottom: '1px solid #eee',
            backgroundColor: isSelected ? '#e0e0e0' : 'transparent',
            color: isLocked ? '#9e9e9e' : 'inherit',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}
    >
        <div>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {`${sectionIndex + 1}.${topicIndex + 1} ${topic.topic}`}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '10px' }}>{topic.description}</Typography>
        </div>
        {isLocked && <LockIcon fontSize="small" />}
    </motion.div>
));

const Section = memo(({ title, theoryTopics, practicalTopics, sectionIndex, selectedTopic, toggleTopic }) => (
    <div>
        <Typography variant="h6">{title}</Typography>
        {theoryTopics.map((topic, topicIndex) => (
            <Topic
                key={`${sectionIndex}-theory-${topicIndex}`}
                topic={topic}
                sectionIndex={sectionIndex}
                topicIndex={topicIndex}
                onClick={() => toggleTopic(sectionIndex, topicIndex, 'theory')}
                isSelected={selectedTopic === `${sectionIndex}-theory-${topicIndex}`}
                isLocked={topic.status === 'locked'}
            />
        ))}
        {practicalTopics.map((topic, topicIndex) => (
            <Topic
                key={`${sectionIndex}-practical-${theoryTopics.length + topicIndex}`}
                topic={topic}
                sectionIndex={sectionIndex}
                topicIndex={theoryTopics.length + topicIndex}
                onClick={() => toggleTopic(sectionIndex, theoryTopics.length + topicIndex, 'practical')}
                isSelected={selectedTopic === `${sectionIndex}-practical-${topicIndex}`}
                isLocked={topic.status === 'locked'}
            />
        ))}
    </div>
));

function CourseDetails() {
    const theme = useTheme();
    const { id } = useParams();
    const navigate = useNavigate();
    const [courseSections, setCourseSections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState('panel0');
    const [selectedTopic, setSelectedTopic] = useState(null);
    const intervalRef = useRef(null);
    const { getAccessTokenSilently } = useAuth0();
    const [courseTitle, setCourseTitle] = useState("");
    const [imageUrls, setImageUrls] = useState([]); // Ensure this is initialized as an empty array
    const [curriculumId, setCurriculumId] = useState();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await getAccessTokenSilently();
                const response = await axios.get(`${BASE_URL}curriculum/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.data && response.data !== "No data found") {
                    if (response.data.curriculum && response.data.curriculum.sections) {
                        let firstLockedFound = false;
                        const sections = response.data.curriculum.sections.map(section => {
                            const updatedTheory = section.theory.map((topic, index) => {
                                if (!firstLockedFound && (!topic.status || topic.status === 'locked')) {
                                    topic.status = 'unlocked';
                                    firstLockedFound = true;
                                } else {
                                    topic.status = topic.status || 'locked';
                                }
                                return topic;
                            });
                            const updatedPractical = section.practical.map((topic, index) => {
                                if (!firstLockedFound && (!topic.status || topic.status === 'locked')) {
                                    topic.status = 'unlocked';
                                    firstLockedFound = true;
                                } else {
                                    topic.status = topic.status || 'locked';
                                }
                                return topic;
                            });
                            return {
                                ...section,
                                theory: updatedTheory,
                                practical: updatedPractical
                            };
                        });
                        setCourseSections(sections);
                        setLoading(false);
                        clearInterval(intervalRef.current);
                        setCourseTitle(response.data.curriculum['course_title']);
                        setImageUrls([response.data.image_url]); // Set the single image URL in an array
                        setCurriculumId(response.data.id);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (loading) {
            intervalRef.current = setInterval(fetchData, 500);
        }

        return () => clearInterval(intervalRef.current);
    }, [loading, getAccessTokenSilently, navigate]);

    const toggleTopic = useCallback((sectionIndex, topicIndex, category) => {
        const topic = category === 'theory'
            ? courseSections[sectionIndex].theory[topicIndex]
            : courseSections[sectionIndex].practical[topicIndex - courseSections[sectionIndex].theory.length];
        const topicId = `${sectionIndex}-${category}-${topicIndex}`;
        if (topic.status === 'locked') return;
        setSelectedTopic(selectedTopic === topicId ? null : topicId);
        navigate('/course-details/section', {
            state: {
                sectionIndex,
                topicIndex,
                sectionName: courseSections[sectionIndex].section_title,
                topicName: topic.topic,
                description: topic.description,
                curriculumId,
                courseTitle,
                imageUrls
            }
        });
    }, [selectedTopic, courseSections, navigate, curriculumId, courseTitle, imageUrls]);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex', height: '100vh', width: '80%', margin: '0 auto' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant="h4" sx={{ marginBottom: '20px', fontWeight: 'bold', textAlign: 'center' }}>{courseTitle}</Typography>
                            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="course options">
                                <Tab label="Course Content" />
                                <Tab label="Feedback" />
                                <Tab label="Course Learning" />
                            </Tabs>
                            <Box sx={{ marginTop: '20px' }}>
                                {tabIndex === 0 && (
                                    <div>
                                        {courseSections.map((section, sectionIndex) => (
                                            <Accordion
                                                key={sectionIndex}
                                                expanded={expanded === `panel${sectionIndex}`}
                                                onChange={handleAccordionChange(`panel${sectionIndex}`)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel${sectionIndex}-content`}
                                                    id={`panel${sectionIndex}-header`}
                                                >
                                                    <Typography variant="h6">{`${sectionIndex + 1}. ${section.section_title}`}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Section


                                                        theoryTopics={section.theory}
                                                        practicalTopics={section.practical}
                                                        sectionIndex={sectionIndex}
                                                        selectedTopic={selectedTopic}
                                                        toggleTopic={toggleTopic}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                )}
                                {tabIndex === 1 && (
                                    <Box>
                                        <Typography variant="h6">Feedback</Typography>
                                        <Typography variant="body1">
                                            {/* Here you can provide feedback about the course. */}
                                        </Typography>
                                        {/* Feedback form or functionality goes here */}
                                    </Box>
                                )}
                                {tabIndex === 2 && (
                                    <Box>
                                        <Typography variant="h6">Course Learning</Typography>
                                        <Typography variant="body1">
                                            Here you can access additional learning materials and resources.
                                        </Typography>
                                        {/* Course learning content or resources go here */}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default CourseDetails;