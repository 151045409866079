import React from 'react';
import ContentLoader from 'react-content-loader';

const HeroSectionSkeleton = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height="100vh"
        viewBox="0 0 1200 800"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="20" y="80" rx="5" ry="5" width="500" height="40" />
        <rect x="20" y="140" rx="5" ry="5" width="400" height="30" />
        <rect x="20" y="200" rx="5" ry="5" width="150" height="50" />
        <rect x="190" y="200" rx="5" ry="5" width="150" height="50" />
        <rect x="650" y="50" rx="10" ry="10" width="500" height="400" />
    </ContentLoader>
);

export default HeroSectionSkeleton;