

import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SpeechComponent from '../components/speech_component';
import PresentationComponent from '../components/slides/presentation_component';
import { BASE_URL } from '../utils/endpoints';

const EnrollCoursePage = ({
    sectionIndex,
    selectedTopic,
    sectionName,
    topicName,
    description,
    iculumId,
    courseTitle
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [generatedContent, setGeneratedContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const audioRef = useRef(null);
    const playPromise = useRef(null);

    const { user, getAccessTokenSilently } = useAuth0();

    const handlePause = () => {
        if (audioRef.current && playPromise.current !== null) {
            playPromise.current.then(() => {
                audioRef.current.pause();
                setIsPlaying(false);
            }).catch(error => {
                console.error("Error while pausing:", error);
            });
        }
    }


    const handlePlay = () => {
        if (audioRef.current) {
            playPromise.current = audioRef.current.play();
            playPromise.current.then(() => {
                setIsPlaying(true);
            }).catch(error => {
                if (error.name === "NotAllowedError") {
                    console.error("Playback cannot start without user interaction.");
                } else {
                    console.error("Error while trying to play audio:", error);
                }
                setIsPlaying(false);
            });
        }
    };


    return (
        <>
            <div style={{
                width: '100%',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                cursor: 'pointer',
                position: 'relative',
                backgroundColor: 'white'
            }}>

                <SpeechComponent onPause={handlePause} onResume={handlePlay} />

                {/* <div style={{ position: 'absolute', top: 5, padding: '10px' }}>
                    {generatedContent && (
                        <PresentationComponent slides={generatedContent} />
                    )}
                </div> */}

            </div>
        </>
    );
}
export default EnrollCoursePage;