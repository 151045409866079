import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { motion } from 'framer-motion';
import {
    Card, CardContent, Typography, Box, Grid, LinearProgress, Skeleton, Alert
} from '@mui/material';
import Lottie from 'lottie-react';
import dashboard from '../assets/lottie/dashboard.json';
import { BASE_URL } from '../utils/endpoints';

const styles = {
    card: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        margin: '0 auto',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
    },
    cardImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '4px 4px 0 0',
    },
    cardContent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    noImageBox: {
        width: '100%',
        height: '150px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
        backgroundSize: '1000px 104px',
        animation: '$shimmer 2s infinite linear'
    },
    '@keyframes shimmer': {
        '0%': { backgroundPosition: '-1000px 0' },
        '100%': { backgroundPosition: '1000px 0' }
    },
    progressPlaceholder: {
        height: '20px',
        marginTop: '20px',
    }
};

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hover: { scale: 1.03, transition: { duration: 0.3 } }
};

const CoursesDashboard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { getAccessTokenSilently, user } = useAuth0();
    const [is404, setIs404] = useState(false);

    useEffect(() => {
        const registerAndFetchData = async () => {
            const token = await getAccessTokenSilently();
            try {
                const registerResponse = await fetch(`${BASE_URL}register`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        auth0_id: user.sub,
                        email: user.email
                    })
                });

                if (registerResponse.status === 200) {
                    const curriculumResponse = await fetch(`${BASE_URL}curriculums`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            auth0_id: user.sub
                        })
                    });

                    if (curriculumResponse.status === 404) {
                        setIs404(true);
                    } else {
                        const data = await curriculumResponse.json();
                        setCourses(data.sort((a, b) => a.id - b.id)); // Sorting courses by id in ascending order
                        setIs404(false);
                    }
                } else {
                    console.error('Registration failed with status:', registerResponse.status);
                }
            } catch (error) {
                console.error('Error during registration or fetching courses:', error);
            } finally {
                setLoading(false);
            }
        };

        registerAndFetchData();
    }, [getAccessTokenSilently, user.sub, user.email]);

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Alert severity="warning" sx={{ mb: 2 }}>
                We are currently implementing major improvements to our platform. During this period, some functionalities might be temporarily unavailable. We apologize for any inconvenience this may cause and appreciate your understanding and patience.
            </Alert>
            {is404 ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Lottie animationData={dashboard} style={{ width: 500, height: 500 }} />
                    <Typography variant="h5" textAlign="center">
                        Please speak with the professor to enroll in a course.
                    </Typography>
                    <Typography variant="p" textAlign="center" padding='20px'>
                        To speak with professor click on the enroll in the sidebar.
                    </Typography>
                </Box>
            ) : (
                <Grid container spacing={1}>
                    {loading ? (
                        Array.from(new Array(8)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Card sx={styles.card}>
                                    <Skeleton variant="rectangular" width="100%" height={200} />
                                    <CardContent sx={styles.cardContent}>
                                        <Skeleton width="60%" />
                                        <Skeleton width="80%" />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        courses.map((course) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={course.id}>
                                <motion.div
                                    variants={cardVariants}
                                    initial="hidden"
                                    animate="visible"
                                    whileHover="hover"
                                    layout
                                >
                                    <Card sx={styles.card} onClick={() => navigate(`/course-details/${course.id}`)}>
                                        <CardContent sx={styles.cardContent}>
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                {course.image_url ? (
                                                    <img
                                                        src={course.image_url}
                                                        alt={course.courseTitle}
                                                        style={styles.cardImage}
                                                        onLoad={() => setCourses(prevCourses => prevCourses.map(c => c.id === course.id ? { ...c, imageLoaded: true } : c))}
                                                        onError={() => setCourses(prevCourses => prevCourses.map(c => c.id === course.id ? { ...c, imageLoaded: true } : c))}
                                                    />
                                                ) : (
                                                    <Box sx={styles.noImageBox}>
                                                        <Typography variant="subtitle1">No Image Available</Typography>
                                                    </Box>
                                                )}
                                                {!course.imageLoaded && (
                                                    <Skeleton variant="rectangular" width="100%" height={150} />
                                                )}
                                            </Box>
                                            <Typography component="div" sx={{ marginTop: '10px', paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold', textAlign: 'center', fontSize: '0.875rem' }}>
                                                {course.courseTitle}
                                            </Typography>
                                            {course.id === 25 ? (
                                                <Box sx={{ width: '100%', mt: 1, paddingLeft: '10px', paddingRight: '10px' }}>
                                                    <LinearProgress variant="determinate" value={10} />
                                                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '10px' }}>{`Progress: 10%`}</Typography>
                                                </Box>
                                            ) : (
                                                <Box sx={styles.progressPlaceholder}></Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                </motion.div>
                            </Grid>
                        ))
                    )}
                </Grid>
            )}
        </Box>
    );
};

export default CoursesDashboard;