import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PresentationComponent from '../slides/presentation_component';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { BASE_URL } from '../../utils/endpoints';

const VideoPlaceholder = ({
    sectionIndex,
    selectedTopic,
    sectionName,
    topicName,
    description,
    curriculumId,
    courseTitle,
    imageUrl
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [generatedContent, setGeneratedContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { user, getAccessTokenSilently } = useAuth0();
    const [topicId, setTopicId] = useState();

    useEffect(() => {

        if (user && selectedTopic !== null) {
            sendUserData();
        }
    }, [user, selectedTopic]);

    const sendUserData = async () => {
        setIsLoading(true);
        const userId = user.sub;
        const authToken = await getAccessTokenSilently();
        const endpoint = `${BASE_URL}topic`;

        const userData = {
            sectionIndex,
            selectedTopic,
            sectionName,
            topicName,
            description,
            curriculumId,
            courseTitle,
            userId,
            imageUrl
        };

        await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify(userData)
        })
            .then(response => response.json())
            .then(data => {
                setGeneratedContent(data.content);
                setTopicId(data.id);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    };

    return (
        <div className="container">
            {isLoading ? (
                <Skeleton height={"60vh"} width={"100%"} count={1} />
            ) : generatedContent ? (
                <PresentationComponent slides={generatedContent} onSlideChange={(index) => { }} curriculumId={curriculumId} sectionName={sectionName} topicName={topicName} description={description} topicId={topicId} />
            ) : (
                <Skeleton height={"60vh"} width={"100%"} count={1} />
            )}
        </div>
    );
};

export default VideoPlaceholder;