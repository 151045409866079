import React from 'react';
import { useLocation } from 'react-router-dom';
import VideoPlaceholder from '../../components/teaching_dashboard_components/video_placeholder';
import { ThemeProvider, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const VideoSection = () => {
    const theme = useTheme();
    const location = useLocation();
    const {
        sectionIndex,
        topicIndex,
        sectionName,
        topicName,
        description,
        curriculumId,
        courseTitle,
        imageUrl
    } = location.state;

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ padding: '8px', display: 'flex', justifyContent: 'center', marginLeft: { xs: '0', md: '60px' } }}>
                <Box sx={{ width: '100%', maxWidth: '1080px', position: 'relative', paddingTop: { xs: '56.25%', md: '56.25%' } }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <VideoPlaceholder
                            sectionIndex={sectionIndex}
                            selectedTopic={topicIndex}
                            sectionName={sectionName}
                            topicName={topicName}
                            description={description}
                            curriculumId={curriculumId}
                            courseTitle={courseTitle}
                            imageUrl={imageUrl}
                        />
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default VideoSection;