import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import HomePage from './pages/home_page';
import Dashboard from './pages/dashboard';
import EnrollCoursePage from './pages/enroll_course';
import Navbar from './components/nav_bar';
import ResponsiveSidebar from './components/side_bar';
import { Box, CircularProgress } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import CourseDetails from './pages/course_details';
import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Demo from './pages/demo';
import * as Sentry from "@sentry/react";

import VideoSection from './components/teaching_dashboard_components/video_section';



Sentry.init({
  dsn: "https://8c162ce46d5fd92f162ce69f4e00dba5@o4507240784199680.ingest.us.sentry.io/4507240795996160",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/VedaAI\.com/,
    /^https:\/\/api\.VedaAI\.com\/api/
  ],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1.0,
});


// Layout component for authenticated users
const AuthenticatedLayout = ({ children }) => (
  <Box>
    <ResponsiveSidebar />
    <Box sx={{ flexGrow: 1 }}>
      {children}
    </Box>
  </Box>
);


const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 6px; 
    height: 6px; 
    padding: 2px;
  }

  /* Style of the track (part the scrollbar can move within) */
  ::-webkit-scrollbar-track {
    background: transparent; /* Minimal visibility when not active */
  }

  /* Style of the handle (scrollable control) */
  ::-webkit-scrollbar-thumb {
    background: #ccc; /* Subtle color */
    border-radius: 3px;

    &:hover {
      background: #bbb; 
    }
  }

`;


const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    // TODO: update with custom loading lottie
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnHover draggable />

        {isAuthenticated ? (
          <>
            <GlobalStyle />
            <Navbar />
            <AuthenticatedLayout>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/enroll-course" element={<EnrollCoursePage />} />
                <Route path='/course-details/:id' element={<CourseDetails />} />
                <Route path="/course-details/section" element={<VideoSection />} />
              </Routes>
            </AuthenticatedLayout>
          </>
        ) : (
          // Routes available for unauthenticated users
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path='/demo' element={<Demo />} />

          </Routes>
        )}
      </Router>
    </ThemeProvider>
  );
};

export default App;