import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { toast } from 'react-toastify';
import { BASE_URL, BASE_WSS } from '../../utils/endpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  border-radius: 12px; 
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background: #274C77;
  position: relative; 
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  padding-top: 20px;
`;

const VideoBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.4);  
  display: flex;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.i`
  color: white;
  font-size: 24px;  
  cursor: pointer;
  margin: 0 8px;
`;

const Content = styled.div`
  width: 100%;
  padding: 20px 0;
  height: 400px;
`;

const SlideTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
`;

const BulletPoints = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
   height: 100%;
     overflow-y: visible;  

`;

const Bullet = styled.li`
  margin: 8px 0;
  padding-left: 20px;
  background-color: ${props => props.active ? '#ddd' : 'transparent'};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #274C77;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #4444dd;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
`;

const NotesContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-top: 20px;
`;

const NotesTitle = styled.h5`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
`;

const Note = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: #666;
  padding: 8px 0;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: #ccc;
  position: relative;
`;

const Progress = styled.div`
  background: #274C77;
  height: 100%;
  width: ${props => props.width};
`;

const TimeDisplay = styled.div`
  color: white;
  font-size: 12px;
`;

// Helper function to format seconds into a readable format
const formatTime = seconds => {
    let minutes = Math.floor(seconds / 60);
    let secondsLeft = Math.floor(seconds % 60);
    return `${minutes}:${secondsLeft < 10 ? '0' + secondsLeft : secondsLeft}`;
};

const PresentationComponent = ({ slides, onSlideChange, curriculumId, sectionName, topicName, description, topicId }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const currentSlide = slides[currentIndex];
    const [bulletIndex, setBulletIndex] = useState(0);
    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    const wsRef = useRef(null);
    const audioContextRef = useRef(null);
    const audioQueueRef = useRef([]);
    const [isPlaying, setIsPlaying] = useState(true);
    const [notes, setNotes] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const isPlayingRef = useRef(isPlaying);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [startTime, setStartTime] = useState(null);
    const titleRef = useRef(null);
    const bulletRefs = useRef([]);
    const slideTitleRef = useRef(null);
    const [currentTopic, setCurrentTopic] = useState(0);

    useEffect(() => {
        const setupInterval = () => {
            if (audioContextRef.current) {
                return setInterval(() => {
                    if (isPlaying && startTime != null && audioContextRef.current) {
                        const newCurrentTime = audioContextRef.current.currentTime - startTime;
                        if (newCurrentTime >= 0) {
                            setCurrentTime(newCurrentTime);
                        }
                    }
                }, 1000);
            }
            return null;
        };

        const intervalId = setupInterval();

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isPlaying, startTime]);

    useEffect(() => {
        if (audioContextRef.current && isPlaying) {
            setStartTime(audioContextRef.current.currentTime - (currentTime || 0));
        } else {
            setStartTime(null);
        }
    }, [isPlaying]);

    useEffect(() => {
        isPlayingRef.current = isPlaying;
    }, [isPlaying]);

    useEffect(() => {
        if (notes) {
            if (slideTitleRef.current) {
                gsap.to(slideTitleRef.current, {
                    y: -slideTitleRef.current.offsetTop + 20,
                    duration: 1,
                    fontSize: '16px',
                });
            }

            gsap.delayedCall(0.1, () => {
                if (bulletRefs.current.length > 0 && titleRef.current) {
                    gsap.to(titleRef.current, { opacity: 0, duration: 0.5 });

                    bulletRefs.current.forEach((bullet, index) => {
                        if (index === 0) {
                            const containerWidth = bullet.parentNode.offsetWidth;
                            const bulletWidth = bullet.offsetWidth;
                            const centerX = (containerWidth) / 4;
                            const bulletRect = bullet.getBoundingClientRect();
                            const slideTitleRect = slideTitleRef.current.getBoundingClientRect();

                            gsap.to(bullet, {
                                x: `+=${centerX - bulletRect.left}`,
                                y: -slideTitleRef.current.offsetTop + 20,
                                duration: 1,
                                fontSize: '22px',
                                fontWeight: 'bold'
                            });
                        } else {
                            gsap.to(bullet, { opacity: 0, duration: 0.5 });
                        }
                    });
                }
            });
        }
    }, [notes]);

    useEffect(() => {
        return () => {
            gsap.killTweensOf(titleRef.current);
            gsap.killTweensOf(slideTitleRef.current);
            bulletRefs.current.forEach(bullet => {
                gsap.killTweensOf(bullet);
            });
            gsap.killTweensOf(gsap.globalTimeline);
        };
    }, []);

    useEffect(() => {
        if (!audioContextRef.current) {
            try {
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            } catch (e) {
                console.error("Failed to create AudioContext:", e);
            }
        }

        const connectWebSocket = async () => {
            if (!isAuthenticated) {
                console.log("User is not authenticated.");
                return;
            }
            try {
                const token = await getAccessTokenSilently();
                const wsUrl = `${BASE_WSS}explain`;
                const newWs = new WebSocket(wsUrl, ['Bearer', token]);
                newWs.binaryType = 'arraybuffer';
                newWs.onopen = () => {
                    console.log("WebSocket is open, starting recording.");
                    startRecording();
                };

                newWs.onclose = () => {
                    console.log('WebSocket connection closed');
                };
                newWs.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };

                newWs.onmessage = (event) => {
                    if (typeof event.data === 'string') {
                        try {
                            const data = JSON.parse(event.data);
                            console.log('Received JSON data:', data);
                            handleCommand(data);
                        } catch (error) {
                            console.error('Failed to parse JSON data:', error);
                        }
                    } else {
                        bufferAudioChunk(event.data);
                    }
                };
                wsRef.current = newWs;
            } catch (error) {
                console.error('Error getting access token silently:', error);
            }
        };

        connectWebSocket();
        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close().then(() => {
                    audioContextRef.current = null;
                    console.log("AudioContext closed successfully.");
                });
            }
        };
    }, []);


    const bufferAudioChunk = (chunk) => {
        if (!audioContextRef.current) {
            console.error("AudioContext is not initialized.");
            return;
        }
        audioContextRef.current.decodeAudioData(chunk.slice(0), (buffer) => {
            if (isPlaying) {
                playBuffer(buffer);
            } else {
                audioQueueRef.current.push(buffer);
            }
        }, (e) => {
            console.error("Error with decoding audio data", e);
        });
    };





    const playBuffer = (buffer, startOffset = 0) => {
        if (!(buffer instanceof AudioBuffer)) {
            console.error('Attempted to play a non-audio buffer item:', buffer);
            return;
        }

        if (!audioContextRef.current) {
            console.error('AudioContext is not initialized.');
            return;
        }

        const source = audioContextRef.current.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContextRef.current.destination);

        let lastEndTime = audioContextRef.current.currentTime;
        if (audioQueueRef.current.length > 0) {
            const lastBuffer = audioQueueRef.current[audioQueueRef.current.length - 1];
            lastEndTime = Math.max(lastEndTime, lastBuffer.endTime);
        }

        // Debugging the values
        console.log('Start Time:', lastEndTime);
        console.log('Start Offset:', startOffset);

        if (!Number.isFinite(lastEndTime) || !Number.isFinite(startOffset)) {
            console.error('Non-finite start time or offset:', lastEndTime, startOffset);
            return;
        }

        try {
            source.start(lastEndTime, startOffset);
        } catch (e) {
            console.error('Failed to start audio source:', e);
        }

        const newEndTime = lastEndTime + buffer.duration - (startOffset || 0);
        audioQueueRef.current.push({ source, buffer, startTime: lastEndTime, endTime: newEndTime });

        source.onended = () => {
            audioQueueRef.current = audioQueueRef.current.filter(src => src.source !== source);
        };
    };




    const togglePlayback = async () => {
        setIsPlaying(!isPlaying);

        if (isPlaying) {
            await audioContextRef.current.suspend();
            console.log("Playback paused.");
        } else {
            if (audioContextRef.current.state === 'suspended') {
                await audioContextRef.current.resume();
            }
            console.log("Resuming playback...");
            while (audioQueueRef.current.length > 0) {
                const audioItem = audioQueueRef.current.shift();
                if (audioItem instanceof AudioBuffer) {
                    playBuffer(audioItem);
                } else {
                    console.error("Skipped non-audio buffer item from queue:", audioItem);
                }
            }
        }
    };


    const stopAudioPlayback = () => {
        console.log('stopping audio playback');
        audioQueueRef.current.forEach(({ source }) => {
            if (source) source.stop();
        });
        audioQueueRef.current = [];
        console.log("Stopped all audio playback.");
    };

    const sendJsonData = async (jsonData) => {
        try {
            const token = await getAccessTokenSilently();
            console.log('sendJsonData');

            await fetch(`${BASE_URL}reqTopicAPI`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData)
            });

        } catch (error) {
            console.error('Failed to send JSON data:', error);
        }
    };

    const rewindAudio = (seconds) => {
        const audioCtx = audioContextRef.current;
        let newTime = audioCtx.currentTime - seconds;
        newTime = Math.max(0, newTime);

        if (!audioQueueRef.current || audioQueueRef.current.length === 0) {
            console.error('Audio queue is empty or not initialized. Nothing to rewind.');
            return;
        }

        let bufferIndex = audioQueueRef.current.findIndex(item => item.endTime > newTime);
        if (bufferIndex === -1 || bufferIndex >= audioQueueRef.current.length) {
            console.error('No suitable buffer found for the requested rewind time. Buffer index:', bufferIndex);
            return;
        }

        if (!audioQueueRef.current[bufferIndex]) {
            console.error('Buffer at calculated index is undefined:', bufferIndex);
            return;
        }

        const bufferToPlay = audioQueueRef.current[bufferIndex];
        if (!bufferToPlay.buffer) {
            console.error('No buffer available to play at index:', bufferIndex);
            return;
        }

        let startOffset = newTime - bufferToPlay.startTime;
        startOffset = Math.max(0, startOffset);

        for (let i = bufferIndex; i < audioQueueRef.current.length; i++) {
            if (audioQueueRef.current[i].source) {
                audioQueueRef.current[i].source.stop();
            }
        }

        audioQueueRef.current = audioQueueRef.current.slice(0, bufferIndex);
        playBuffer(bufferToPlay.buffer, startOffset);
    };


    const fastForwardAudio = (seconds) => {
        const audioCtx = audioContextRef.current;
        let newTime = audioCtx.currentTime + seconds;
        let totalDuration = audioQueueRef.current.reduce((acc, item) => acc + item.buffer.duration, 0);
        newTime = Math.min(newTime, totalDuration);

        let bufferIndex = audioQueueRef.current.findIndex(item => item.endTime > newTime);
        if (bufferIndex !== -1) {
            for (let i = bufferIndex; i < audioQueueRef.current.length; i++) {
                audioQueueRef.current[i].source.stop();
            }
            audioQueueRef.current.length = bufferIndex;

            playBuffer(audioQueueRef.current[bufferIndex].buffer, newTime - audioQueueRef.current[bufferIndex].startTime);
        }
    };


    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.ondataavailable = (event) => {
                    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN && isPlayingRef.current) {
                        wsRef.current.send(event.data);
                    }
                };

                mediaRecorder.start(50);

                const stopRecording = () => {
                    mediaRecorder.stop();
                    stream.getTracks().forEach(track => track.stop());
                };

                wsRef.current.onclose = stopRecording;
                wsRef.current.onerror = stopRecording;
            })
            .catch(error => {
                console.error('Error accessing the media device.', error);
                alert('Could not access microphone. Please check permissions.');
            });
    };


    const handleCommand = (data) => {
        console.log("handling commands", data.action);
        if (data.action) {
            console.log(data.action);
            switch (data.action) {
                case 'sendTopic':
                    console.log('sendTopic to backend');
                    const bulletPoints = currentSlide.content.bullet_points;
                    if (bulletIndex < bulletPoints.length) {
                        sendJsonData({ action: 'topicData', data: slides, current_topic: currentSlide.content, explain_topic: bulletPoints[bulletIndex], curriculumId: curriculumId, sectionName: sectionName, topicName: topicName, explainingHeading: description, topicId: topicId });
                        // setBulletIndex(bulletIndex);
                        console.log({ action: 'topicData', data: slides, current_topic: currentSlide.content, explain_topic: bulletPoints[bulletIndex], curriculumId: curriculumId });
                    }
                    console.log('sent topic');
                    break;

                case 'user-interrupted':
                    stopAudioPlayback();
                    break;
                case 'notes':
                    console.log(data.notes);
                    setNotes(data.notes);
                    setCurrentTopic(data.current_explaining_topic);
                    break;
                case 'curriculumGenerated':
                    // navigate('/dashboard');
                    break;
                case 'ConnectionIssue':
                    console.log('ask user to refresh');
                    toast.error("Connection Lost! Please refresh the page.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
                default:
                    console.log('Unknown command:', data.action);
                    break;
            }
        }
    };




    const handleNext = () => {
        if (currentIndex < slides.length - 1) {
            setCurrentIndex(currentIndex + 1);
            onSlideChange(currentIndex + 1);
        }
    };

    const handleBack = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            onSlideChange(currentIndex - 1);
        }
    };

    return (
        <>
            <div style={{ position: 'relative' }}>
                <Container onClick={togglePlayback}>
                    <Title ref={titleRef} style={{ color: 'white' }}>{currentSlide.title}</Title>                    <Content>
                        <SlideTitle ref={slideTitleRef} style={{ color: 'white' }}>{currentSlide.content.title}</SlideTitle>
                        <BulletPoints>
                            {currentSlide.content.bullet_points?.map((point, index) => (
                                <Bullet ref={el => bulletRefs.current[index] = el} key={index} style={{ color: 'white' }}>{point}</Bullet>
                            ))}
                        </BulletPoints>
                    </Content>
                </Container>
                <VideoBar>
                    <Icon className="material-icons" onClick={() => togglePlayback()}>
                        {isPlaying ? 'pause' : 'play_arrow'}
                    </Icon>

                    <Icon className="material-icons" onClick={() => rewindAudio(5)}>fast_rewind</Icon>
                    <Icon className="material-icons" onClick={() => fastForwardAudio(5)}>fast_forward</Icon>
                    <TimeDisplay style={{ paddingRight: '12px' }}>
                        {formatTime(currentTime)}
                    </TimeDisplay>
                    <ProgressBar>
                        <Progress width={`${(currentTime / totalDuration) * 100}%`} />
                    </ProgressBar>
                    <Icon className="material-icons">mic</Icon>
                    <Icon className="material-icons">volume_up</Icon>
                    <Icon className="material-icons">settings</Icon>
                </VideoBar>
            </div>
            <ButtonsContainer>
                <Button onClick={handleBack} disabled={currentIndex === 0}>Back</Button>
                <Button onClick={handleNext} disabled={currentIndex === slides.length - 1}>Next</Button>
            </ButtonsContainer>
            {notes && (<h3 style={{ marginRight: '10px' }}>Today we will be learning below topics</h3>
            )}
            {notes && (
                <NotesContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ marginRight: '10px' }}>Notes:</h4>
                        {currentSlide.content.bullet_points && bulletIndex < currentSlide.content.bullet_points.length && (
                            <h4 key={bulletIndex}>{currentSlide.content.bullet_points[bulletIndex]}</h4>
                        )}
                    </div>
                    <NotesTitle>{notes.course_title}</NotesTitle>
                    {notes.notes?.map((note, index) => (
                        <Note key={index}>{note}</Note>
                    ))}
                </NotesContainer>
            )}
        </>
    );
};
export default PresentationComponent;
