import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import {
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { Parallax } from "react-parallax";
import { motion } from "framer-motion";
import animationData from "../../assets/lottie/dashboard.json";
import backgroundImage from "../../assets/images/plain.jpg";
import HeroSectionSkeleton from "./hero_section_skeleton";

const HeroSection = ({ onLearnMoreClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      transparent: true,
    },
  };

  useEffect(() => {
    const image = new Image();
    image.src = backgroundImage;
    image.onload = () => setLoading(false);
  }, []);

  if (loading) {
    return <HeroSectionSkeleton />;
  }

  return (
    <Parallax bgImage={backgroundImage} strength={500}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          textAlign: "center",
        }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            zIndex: 1,
          }}
        />
        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            variant={isMobile ? "h3" : "h2"}
            component="h1"
            gutterBottom
            sx={{ mb: 8 }}>
            Welcome to the Future of Education
          </Typography>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}>
                <Box mb={4}>
                  <Typography variant={isMobile ? "h6" : "h5"} paragraph>
                    Bridge the gap to personalized, interactive, and real-world
                    education.
                  </Typography>
                  <Typography paragraph>
                    Every section offers hands-on practice by contributing to
                    open-source projects, providing the real-world exposure
                    essential for success in any company—something traditional
                    learning fails to provide.
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      marginRight: "16px",
                      backgroundColor: "#274C77",
                    }}
                    onClick={() =>
                      window.open(
                        "https://forms.gle/JZPQjPZbcBE2xJZC6",
                        "_blank"
                      )
                    }>
                    Join Program
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    style={{ color: "#000", borderColor: "#000" }}
                    onClick={onLearnMoreClick}>
                    Learn More
                  </Button>
                </Box>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}>
                <Lottie
                  options={defaultOptions}
                  height={isMobile ? 300 : 500}
                  width={isMobile ? 300 : 500}
                />
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Parallax>
  );
};

export default HeroSection;
