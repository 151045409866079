import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: "'Mulish', sans-serif",
    },
    palette: {
        background: {
            default: '#ffffff',
        },
        primary: {
            main: '#274C77',
        },
        secondary: {
            main: '#6096BA',
        },
        info: {
            main: '#A3CEF1',
        },
        text: {
            primary: '#2b2c28',
        },
    },
});

export default theme;

