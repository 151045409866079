import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import ResponsiveSidebar from '../components/side_bar';
import CoursesDashboard from '../pages/courses_dashboard';

const Dashboard = () => {
    const theme = useTheme();
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = await getAccessTokenSilently();
            } catch (e) {
                console.log(e);
            }
        };
        fetchUserData();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, isLoading, navigate]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
                <ResponsiveSidebar />
                <Box sx={{ flexGrow: 1, overflow: 'auto', p: 3, backgroundColor: 'white' }}>
                    <CoursesDashboard />
                </Box>
            </Box>
        </ThemeProvider>
    );
}
export default Dashboard;