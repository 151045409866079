import React from 'react';
import Navbar from '../components/nav_bar';
import Footer from '../components/footer';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../theme';
import demo from '../assets/videos/demo.mp4';

const Demo = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ minHeight: '100vh', backgroundColor: 'white' }}>
                <Navbar />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <video
                        style={{ width: '100%', height: 'auto' }}
                        src={demo}
                        autoPlay
                        loop
                        controls
                        controlsList="nodownload" 
                    />
                </div>
                <Footer />
            </div>
        </ThemeProvider>
    );
};

export default Demo;
