import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'; // Icon for the "Enroll" item

export default function ResponsiveSidebar() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <CssBaseline />
            <Drawer
                sx={{
                    width: isHovered ? 240 : 56,
                    flexShrink: 0,
                    transition: 'width 0.3s ease-in-out',
                    '& .MuiDrawer-paper': {
                        width: isHovered ? 240 : 56,
                        overflowX: 'hidden',
                        color: 'text.primary',
                        backgroundColor: '#ffffff',
                    },
                    '&:hover': {
                        width: 240,
                    },
                }}
                variant="permanent"
                open
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                ModalProps={{
                    keepMounted: true,
                    disablePortal: true,
                    hideBackdrop: true,
                }}
            >
                <List>
                    {[
                        { text: "Home", icon: <HomeOutlinedIcon />, to: "/dashboard" },
                        { text: "Notes", icon: <NoteOutlinedIcon />, to: "/notes" },
                        { text: "Assignments", icon: <AssignmentOutlinedIcon />, to: "/assignments" },
                        { text: "Enroll", icon: <SchoolOutlinedIcon />, to: "/enroll-course" },
                        { text: "Settings", icon: <SettingsOutlinedIcon />, to: "/settings" }
                    ].map((item, index) => (
                        <ListItem button key={index} component={Link} to={item.to}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} sx={{ display: isHovered ? 'block' : 'none' }} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
}
