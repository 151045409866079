import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import NotificationsIcon from '@mui/icons-material/Notifications';
const buttonStyle = {
    backgroundColor: "transparent",
    color: "#2b2c28",
    border: "none",
    padding: "10px 20px",
    textAlign: "center",
    display: "inline-block",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: "pointer",
    transition: "color 0.1s ease-in-out",
};

const hoverStyle = {
    color: "#274C77",
};


export const LoginButton = () => {
    const { loginWithRedirect, isLoading } = useAuth0();
    const [isHovered, setIsHovered] = React.useState(false);
    if (isLoading) return null;

    return (
        <button
            style={{ ...buttonStyle, ...(isHovered ? hoverStyle : {}) }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => loginWithRedirect()}
        >
            Log In
        </button>
    );
};

export const LogoutButton = () => {
    const { logout, isLoading } = useAuth0();
    const [isHovered, setIsHovered] = React.useState(false);
    const [isExpanded, setIsExpanded] = useState();

    if (isLoading) return null;

    return (
        <>
            <div style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                color: '#274C77',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                paddingRight: '16px'
            }}>
                <NotificationsIcon />
            </div>
            <div style={{
                width: '36px',
                height: '36px',
                borderRadius: '50%',
                backgroundColor: '#274C77',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
                position: 'relative',
                cursor: 'pointer'
            }} onClick={() => setIsExpanded(!isExpanded)}>
                S
                {isExpanded && (
                    <div style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        backgroundColor: 'white',
                        color: '#274C77',
                        borderRadius: '5px',
                        boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div style={{ padding: '10px', cursor: 'pointer' }} >
                            Help
                        </div>
                        <div style={{ padding: '10px', cursor: 'pointer' }} onClick={logout}>
                            Logout
                        </div>
                    </div>
                )}
            </div>

        </>
    );
};
const AuthButtons = { LoginButton, LogoutButton };

export default AuthButtons;